import { defineStore } from "pinia";
import { CoInvestor, InvestorContactPowers, TargetInvestorType } from "~/generated/operations-airfund";
import { useRuntimeConfig } from '#imports';
import { z } from 'zod'
import { Theme } from '~/types/theme'
import { defaultThemes } from '~/theme'
import { HumanizeOptions } from "~/utils/numbers";
import { Locale } from "~/utils/dates";

export interface State {
  name: string
  url: string
  id: string
  imageUrl: string
  content: string
  user: {
    id?: string
    name: string
    email: string
    imageUrl: string
    role: string
    investors: Investor[]
    preferredLanguage: string
    usPerson: boolean
    investorType: TargetInvestorType
    nationality: string
    impersonationMode: boolean
    emailValidated: boolean
  }
  contacts: {
    categories: string[],
    name: string,
    phone: string,
    email: string
  }[],
  selectedInvestorId?: string | null
  settings: {
    ENABLE_PRODUCTS: boolean
    DISABLE_PERSONAL_DOCUMENTS_TAB: boolean
    DISABLE_COMPANY_PANEL: boolean
    ENABLE_SETTINGS: boolean
    ENABLE_HOME_VALORIZATION: boolean
    DISABLE_SUBSCRIPTION_DOCUMENTS_TAB: boolean
    DISPLAY_CAPITAL_CALLS: boolean
    BANNER: string | null
    THEME: 'DEMO' | 'CUSTOM' | null
    CUSTOM_THEME: z.infer<typeof Theme> | null
    FUNDS_CATEGORIZATION_METHOD: 'CURRENCY' | 'ASSET_CLASS' | 'TAGS'
    ENABLE_NUMBER_FORMATTING: boolean
    DOCUMENTS_SORTING: 'DATE_ASC' | 'NAME_ASC'
    FOOTER_DISCLAIMER: Record<Locale, string>
    PRIORITIZE_GROSS_AMOUNT_FOR_TRADES: boolean
    TRANSFER_CASHEVENTS_DETAILED: boolean
  }
}

export interface Investor {
  id: string
  name: string
  keyNumbers: {
    code: string
    value: {
      value: number
      display: string
    }
  }[]
  type: string
  powers: InvestorContactPowers[]
  coInvestorRate: CoInvestor["rate"]
  coInvestorType: CoInvestor["type"]
}

export const useCompanyStore = defineStore('company', {
  state: (): State => {
    const config = useRuntimeConfig()
    return {
      name: '',
      url: '',
      id: '',
      imageUrl: '',
      content: '',
      user: {
        id: '',
        name: '',
        email: '',
        imageUrl: '',
        role: '',
        investors: [],
        preferredLanguage: 'en',
        usPerson: false,
        investorType: TargetInvestorType.Retail,
        nationality: '',
        impersonationMode: false,
        emailValidated: false
      },
      contacts: [],
      selectedInvestorId: null,
      settings: {
        ENABLE_PRODUCTS: config.public.ENABLE_PRODUCTS,
        DISABLE_PERSONAL_DOCUMENTS_TAB: config.public.DISABLE_PERSONAL_DOCUMENTS_TAB,
        DISABLE_COMPANY_PANEL: config.public.DISABLE_COMPANY_PANEL,
        ENABLE_SETTINGS: config.public.ENABLE_SETTINGS,
        ENABLE_HOME_VALORIZATION: config.public.ENABLE_HOME_VALORIZATION,
        DISABLE_SUBSCRIPTION_DOCUMENTS_TAB: config.public.DISABLE_SUBSCRIPTION_DOCUMENTS_TAB,
        DISPLAY_CAPITAL_CALLS: true,
        BANNER: null,
        THEME: null,
        CUSTOM_THEME: null,
        ENABLE_NUMBER_FORMATTING: true,
        FUNDS_CATEGORIZATION_METHOD: 'TAGS',
        DOCUMENTS_SORTING: 'DATE_ASC',
        FOOTER_DISCLAIMER: {
          en: '',
          fr: ''
        },
        PRIORITIZE_GROSS_AMOUNT_FOR_TRADES: false,
        TRANSFER_CASHEVENTS_DETAILED: config.public.TRANSFER_CASHEVENTS_DETAILED
      }
    }
  },
  getters: {
    selectedInvestor: (state: State): Investor => {
      return state.user.investors.find(investor => investor.id === state.selectedInvestorId) as Investor
    },
    sortedContacts: (state: State) => {
      return state.contacts.sort(function(a, b){
        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;
      })
    },
    isDataAvailable(): boolean {
      try {
        if (this.user.investors.every((investor) => investor.keyNumbers.length === 0)) { // key numbers are still lazy loading
          return true
        }
        return (this.selectedInvestor as Investor).keyNumbers.some(keyNumber => keyNumber.value.value)
      } catch {
        return false
      }
    },
    theme: (state: State): z.infer<typeof Theme> => {
      switch (state.settings.THEME) {
        case 'CUSTOM':
          return state.settings.CUSTOM_THEME as z.infer<typeof Theme>
        case 'DEMO':
          return defaultThemes['DEMO']
        default:
          const config = useRuntimeConfig()
          return defaultThemes?.[config.public.COMPANY] || defaultThemes['DEMO']
      }
    },
    numberForceType: function(this): HumanizeOptions['forceType'] {
      if (this.settings.ENABLE_NUMBER_FORMATTING) {
        let type: HumanizeOptions['forceType'] = 'MILLION'
  
        try {
          const commitment = (this.selectedInvestor as Investor).keyNumbers.find(keyNumber => keyNumber.code === 'engaged')
          if (commitment!.value.value < 500000) {
            type = 'NONE'
          } else if (commitment!.value.value < 2000000) {
            type = 'KILO'
          }
        } catch {}
  
        return type
      }
      return 'NONE'
    }
  }
})